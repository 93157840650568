import React from 'react';

import Layout from '../components/layout';

const ResourcesPage = () => {
    return (
    <Layout title="Resources">
        <div className="max-width-container" style={{minHeight: 400}}>
            <div className="DisplayBlackCenter mb40">Resources</div>
            <ul>
                <li><a className='Resource_url' href="https://docs.google.com/document/d/1ntsCIKcfgxWIofrAbP8yRQNz2MZnX6B9/edit?usp=sharing&ouid=100138329808241445313&rtpof=true&sd=true" target="_blank" rel="noopener noreferrer">TicketTamer New Client Welcome Letter</a></li>
                <li><a className='Resource_url' href="https://docs.google.com/document/d/1A5aYjHiUbqPbHOYhhqvcSxygnhQp02YQ/edit?usp=drive_link&ouid=100138329808241445313&rtpof=true&sd=true" target="_blank" rel="noopener noreferrer">What to Know About Your Court Appearance</a></li>
                <li><a className='Resource_url' href="https://docs.google.com/document/d/1xh9IBk_snjLViDms-h8fh942Wjw4Qpvq/edit?usp=drive_link&ouid=100138329808241445313&rtpof=true&sd=true" target="_blank" rel="noopener noreferrer">How to Pay Your Court Fine</a></li>
            </ul>
        </div>
    </Layout>
    )
}
export default ResourcesPage;
